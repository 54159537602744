import styles from './LastViewedProducts.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { Placeholder } from 'components/primitives/placeholders';
import { routesBuilder } from 'routes';
import ProductTilesGroup from '../productTilesGroup';

const LastViewedProductsGroup = ({ id, clearLastViewedProducts, ...props }) => {
  const backTo = useCurrentRouteAsBackTo();
  const textPlaceholder = <Placeholder className={styles.placeholder} />;
  const links = (
    <>
      <Link
        className={styles.viewAll}
        to={routesBuilder.forLastViewed}
        options={{ backTo }}
      >
        <SimpleText textKey="ViewAll" placeholder={textPlaceholder} />
      </Link>
      <button className={`${linkStyles.link} ${styles.clearViewed}`} onClick={clearLastViewedProducts}>
        <SimpleText textKey="LastViewedProducts_ClearLinkText" placeholder={textPlaceholder} />
      </button>
    </>
  );

  return <ProductTilesGroup id={`lastViewedProducts${id ? '_' + id : ''}`} headerTextKey="LastViewedProducts_Header" links={links} {...props} />;
};

LastViewedProductsGroup.propTypes = {
  id: PropTypes.string,
  clearLastViewedProducts: PropTypes.func,
};

export default LastViewedProductsGroup;
