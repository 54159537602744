exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductTilesGroup_wrapper{line-height:1.3;width:100%}.ProductTilesGroup_with-margins{margin:4.37em 0}.ProductTilesGroup_heading,.ProductTilesGroup_heading-placeholder{display:inline-block}.ProductTilesGroup_heading{cursor:text;font-family:var(--theme-tabHeader_FontFamily,var(--theme-baseFontFamily,\"Pathway Gothic One\")),sans-serif;font-size:var(--theme-tabHeader_Size,2.3em);margin:0;text-transform:var(--theme-h2_TextTransform,uppercase)}.ProductTilesGroup_heading-placeholder{font-size:var(--theme-text_Size,13px);height:var(--theme-tabHeader_Size,2.3em);vertical-align:bottom;width:14em}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.ProductTilesGroup_wrapper>header{padding-bottom:.62em}}", ""]);

// exports
exports.locals = {
	"wrapper": "ProductTilesGroup_wrapper",
	"with-margins": "ProductTilesGroup_with-margins",
	"withMargins": "ProductTilesGroup_with-margins",
	"heading": "ProductTilesGroup_heading",
	"heading-placeholder": "ProductTilesGroup_heading-placeholder",
	"headingPlaceholder": "ProductTilesGroup_heading-placeholder"
};