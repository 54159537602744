import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearLastViewedProducts, requestLastViewedProducts } from 'behavior/products/lastViewedTracking';
import LastViewedProductsGroup from './LastViewedProductsGroup';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { trackProductListView, EventSource } from 'behavior/analytics';
import { ProductTrackingContext } from '../analytics';
import { iEquals } from 'utils/helpers';

const productsCountToShow = 5;

const LastViewedProducts = ({ id, withMargins }) => {
  const dispatch = useDispatch();
  const { lastViewed, product } = useSelector(({
    lastViewed,
    page: {
      product,
    },
  }) => ({
    lastViewed,
    product,
  }), shallowEqual);
  const { products, expired, lastViewed: lastViewedProductId } = lastViewed;

  useEffect(() => {
    if (lastViewedProductId && !product) {
      dispatch(requestLastViewedProducts(false, productsCountToShow));
      return;
    }

    if (!products || expired)
      dispatch(requestLastViewedProducts(!!product, productsCountToShow));
  }, [products, product, expired, lastViewedProductId]);

  useEffect(() => {
    if (!products || !products.length)
      return;

    const calculated = products.every(product => typeof product.price !== 'undefined');
    if (!calculated)
      return;

    const pdpTrackCondition = product && lastViewedProductId && iEquals(product.id, lastViewedProductId);

    if (pdpTrackCondition || (!product && !lastViewedProductId))
      dispatch(trackProductListView({ products, source: EventSource.LastViewedProducts }));
  }, [products]);

  const trackingContextValue = useMemo(() => ({ trackingSource: EventSource.LastViewedProducts }), []);

  if (!products || !products.length)
    return null;

  return (
    <ProductTrackingContext.Provider value={trackingContextValue}>
      <LastViewedProductsGroup id={id} products={products} clearLastViewedProducts={() => dispatch(clearLastViewedProducts())} withMargins={withMargins} />
    </ProductTrackingContext.Provider>
  );
};

LastViewedProducts.propTypes = {
  id: PropTypes.string,
  withMargins: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
export default props => {
  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  return canViewCatalog && <LastViewedProducts {...props} />;
};
