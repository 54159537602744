import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LastViewedProducts from './LastViewedProducts';
import loadable from '@loadable/component';

let DesignerModeLastViewedProducts;

const LastViewedProductsBlock = ({ id, lastViewedEnabled, isDesignerMode }) => {
  if (isDesignerMode) {
    if (!DesignerModeLastViewedProducts)
      DesignerModeLastViewedProducts = loadable(() => import(/*webpackChunkName:"vd"*/'./DesignerModeLastViewedProducts'));

    return <DesignerModeLastViewedProducts id={id} />;
  }

  return lastViewedEnabled && <LastViewedProducts id={id} />;
};

LastViewedProductsBlock.propTypes = {
  id: PropTypes.string.isRequired,
  lastViewedEnabled: PropTypes.bool,
  isDesignerMode: PropTypes.bool,
};

export default connect(state => ({
  lastViewedEnabled: state.settings?.lastViewedEnabled,
}))(LastViewedProductsBlock);
